<style>

</style>

<template>
<v-container lighten-5>
    <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="cocuId" class="elevation-1">
        <template v-slot:header>
            <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
            <v-toolbar dark color="primary darken-3" class="mb-1">
                <v-toolbar-title class="white--text">Cups</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                    agregar
                </v-btn>
                <v-form @submit.prevent="subida" enctype="multipart/form-data">
                    <v-dialog v-model="dialogmasivo" persistent width="500">
                        <template v-slot:activator="{ on }">
                            <v-btn color="red lighten-2" dark v-on="on">
                                Carga masiva
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-title class="headline grey lighten-2" primary-title>
                                Cargar excel
                            </v-card-title>

                            <v-card-text>
                                <v-row v-if="!loader">
                                    <v-col cols="12" sm="12" md="12">
                                        <v-file-input outlined v-model="file" show-size label="Excel" @change="onFileChange"></v-file-input>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col cols="12" sm="12" md="12" class="text-center">
                                        <span class="headline">CARGANDO CUPS....</span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" class="text-center">
                                        <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
                                    </v-col>
                                </v-row>

                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-btn color="primary" text target="_blank" :href="plantilla">
                                    Descargar formato
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="red" v-if="!loader" text @click="dialogmasivo = !dialogmasivo">
                                    cancelar
                                </v-btn>
                                <v-btn color="primary" v-if="!loader" text @click="subida">
                                    cargar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-form>
                <div class="flex-grow-1"></div>
            </v-toolbar>
        </template>
        <template v-slot:default="props">
            <v-row align="center" justify="center">
                <v-col v-for="item in props.items" :key="item.cocuId" cols="12" sm="12" md="10" lg="10">
                    <v-card>
                        <v-card-title class="subtitle-1 font-weight-bold">
                            <v-row no-gutters>

                                <v-col cols="9" sm="9" md="9" lg="9">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="5" md="5" lg="5">
                                            CUPS <span class="primary--text">
                                                {{ item.cocuCups}}
                                            </span>
                                        </v-col>
                                        <v-col cols="12" sm="7" md="7" lg="7">
                                            AGRUPACION <span class="green--text"> {{item.cocuAgrupacionservicio}}</span>
                                        </v-col>

                                        <v-col cols="12" sm="12" md="12" lg="12">
                                            NOMBRE <span class="primary--text">
                                                {{item.cocuNombre}}
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="3" sm="3" md="3" lg="3">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn outlined color="info" class="mr-2" v-on="on" small @click.stop="dialog = true" @click="editar(item.cocuId)">
                                                <v-icon>create</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn outlined small color="error" v-on="on" @click="eliminar(item.cocuId)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Eliminar</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" sm="3" md="3" lg="3" v-if="item.area">
                                    AREA <span class="primary--text">
                                        {{ item.area.comaNombrecorto }}
                                    </span>
                                </v-col>
                                <v-col cols="12" sm="3" md="3" lg="3">
                                    UNIDAD
                                    <span class="primary--text"> {{ item.cocuUnidad}} </span>
                                </v-col>
                                <v-col cols="12" sm="3" md="3" lg="3">
                                    REFERENCIA <span class="primary--text"> {{ item.cocuReferencia}}</span>
                                </v-col>
                                <v-col cols="12" sm="3" md="3" lg="3" v-if="item.nivel">
                                    NIVEL <span class="primary--text"> {{ item.nivel.comaNombrecorto}}</span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-slot:no-data>
            <v-row class="mt-2" align="center" justify="center">
                <v-col cols="10" sm="10" md="10" lg="10">
                    <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                        No se encontro nada.
                    </v-alert>
                </v-col>
            </v-row>
        </template>
        <template v-slot:footer>
            <v-row class="mt-2 pb-5" align="center" justify="center">
                <span class="mr-4 grey--text">
                    Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                </span>
                <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

            </v-row>
        </template>
    </v-data-iterator>
    <v-form @submit.prevent="agregar">
        <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row>

                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined label="Cups" :error="$v.form.cocuCups.$error" v-model="form.cocuCups"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined :error="$v.form.cocuNombre.$error" v-on:keyup="form.cocuNombre = $event.target.value.toUpperCase()" label="Nombre" v-model="form.cocuNombre"></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined label="Agrupacionservicio" v-model="form.cocuAgrupacionservicio"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <v-text-field outlined v-on:keyup="form.cocuReferencia = $event.target.value.toUpperCase()" label="Referencia" v-model="form.cocuReferencia"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-autocomplete outlined required v-model="form.cocuTipoArea" :items="tipoarea" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Area" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-autocomplete outlined required v-model="form.cocuTipoNivel" :items="tiponivel" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Nivel" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="4">
                                <v-text-field outlined v-on:keyup="form.cocuUnidad = $event.target.value.toUpperCase()" label="Unidad" v-model="form.cocuUnidad"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                    <v-file-input outlined v-model="file" show-size label="Cabecera" @change="onFileChange"></v-file-input>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>

</v-container>
</template>

<script>
import plantilla from '@/assets/files/plantilla_cup.xlsx';
import {
    required
}
from 'vuelidate/lib/validators';


export default {
    components: {
        
    },
    data() {
        return {
            dialog: false,
            isNew: true,
            Count: 0,
            plantilla: null,
            loader: false,
            dialogmasivo: false,
            file: null,
            page: 1,
            pageCount: 0,
            url: "sistema/cups/",
            token: this.$cookies.get("token"),
            files: [],
            form: {
                cocuId: null,
                cocuCups: null,

                cocuNombre: null,

                cocuAgrupacionservicio: null
            },

            filtros: [{
                    text: "Cups",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                              listatext: "nombre del campo que se mostrara",
                              listavalue: "nombre del valor que se mandara"*/
                }, {
                    text: "Nombre",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                              listatext: "nombre del campo que se mostrara",
                              listavalue: "nombre del valor que se mandara"*/
                }, {
                    text: "Agrupacionservicio",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                              listatext: "nombre del campo que se mostrara",
                              listavalue: "nombre del valor que se mandara"*/
                }, {
                    text: "Area",
                    value: null,
                    tipo: "lista",
                    lista: [],
                    listatext: "comaNombrelargo",
                    listavalue: "comaId"
                }, {
                    text: "Referencia",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                              listatext: "nombre del campo que se mostrara",
                              listavalue: "nombre del valor que se mandara"*/
                },
                {
                    text: "Nivel",
                    value: null,
                    tipo: "lista",
                    lista: [],
                    listatext: "comaNombrelargo",
                    listavalue: "comaId"
                },
            ],

            lista: [],
            listaCopia: [],
            tipoarea: [],
            tiponivel: []
        };
    },
    validations: {
        form: {

            cocuCups: {
                required,
            },

            cocuNombre: {
                required,
            },

        },
    },
    computed: {
        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    created: async function () {
        this.plantilla = plantilla;
        this.$store.commit('setCargaDatos', true);
        this.filtros[3].lista = this.tipoarea = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSAREASLABORATORIOS").then(data => data);
        this.filtros[5].lista = this.tiponivel = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSNIVELESLABORATORIOS").then(data => data);
        await this.buscar(this.filtros);
        this.$store.commit('setCargaDatos', false);
        this.limpiar();
    },

    methods: {
        onFileChange(file) {
            this.file = file;
        },
        async searchCup(searchP) {
            this.$store.commit('setCargaDatos', true);
            const data = await this.$apiService.index(`sistema/cups/buscar2?${searchP}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.$store.commit('setCargaDatos', false);
        },
        async buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;
            var search = `offset=${(this.page-1)*10}&`;

            if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
                search = search + `cocuCups=${this.filtros[0].value}&`;
            }
            if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
                search = search + `cocuNombre=${this.filtros[1].value}&`;
            }
            if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
                search = search + `cocuAgrupacionservicio=${this.filtros[2].value}&`;
            }
            if (this.filtros[3].value != null && this.filtros[3].value.toString().replace(/ /g, "") != "") {
                search = search + `cocuTipoArea=${this.filtros[3].value}&`;
            }
            if (this.filtros[4].value != null && this.filtros[4].value.toString().replace(/ /g, "") != "") {
                search = search + `cocuReferencia=${this.filtros[4].value}&`;
            }
            if (this.filtros[5].value != null && this.filtros[5].value.toString().replace(/ /g, "") != "") {
                search = search + `cocuTipoNivel=${this.filtros[54].value}&`;
            }
            if (search != "") {
                await this.searchCup(search);
            }
        },
        async subida() {
            try {
                if (this.file != null) {
                    let formData = new FormData();
                    formData.append('file', this.file);
                    this.loader = true;
                    let data = await this.$apiService.createFile(this.url + "masiva", formData).then(data => data);
                    if (data) {
                        this.loader = false;
                        this.file = null;
                        this.$store.commit('setCargaDatos', true);
                        await this.buscar(this.filtros);
                        this.$store.commit('setCargaDatos', false);
                        this.$swal.fire({
                            title: "Completado!",
                            text: "Subida exitosa",
                            icon: "success",
                            confirmButtonText: "Ok"
                        });
                    }

                } else {
                    this.loader = false;
                    this.$swal.fire({
                        title: "Cuidado!",
                        text: "Selecciona un archivo primero",
                        type: "warnning",
                        confirmButtonText: "Ok"
                    });
                }

            } catch (error) {
                this.loader = false;
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }
            this.dialogmasivo = false;

        },
        async agregar() {
            this.$v.form.$touch();
            if (!this.$v.form.$error) {
                try {
                    if (this.isNew) {
                        let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
                        if (data) {
                            if (this.file) {
                                    let formData = new FormData();
                                    formData.append('file', this.file);
                                    await this.$apiService.createFile(this.url + `cabecera?codigo=${data.cocuCups}&cocuId=${data.cocuId}`, formData).then(data => data);
                                }
                            this.listaCopia = this.lista.push(data);
                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                        }

                    } else {
                        var id = this.form.cocuId;
                        let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
                        if (data) {
                            if (this.file) {
                                    let formData = new FormData();
                                    formData.append('file', this.file);
                                    await this.$apiService.createFile(this.url + `cabecera?codigo=${this.form.cocuCups}&cocuId=${id}`, formData).then(data => data);
                                }
                            this.lista = this.lista.filter(e => e.cocuId != id);
                            this.listaCopia = this.lista.push(data);
                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                        }

                    }
                    this.close();
                } catch (error) {
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }

            }
        },
        async eliminar(id) {
            try {
                var result = await this.$swal.fire({
                    title: "Estas seguro?",
                    text: "No podras revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, eliminar!"
                }).then(result => result.value);
                if (result) {
                    let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
                    if (data) {
                        this.listaCopia = this.lista = this.lista.filter(
                            e => e.cocuId != id
                        );
                        this.$swal.fire(
                            "Eliminado!",
                            "ha sido eliminado con exito.",
                            "success"
                        );
                    }
                }

            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        async editar(id) {
            try {
                this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
                this.isNew = false;
                if(this.form.cocuCabecera){
                    let data = await this.$apiService.index(this.url + `getCabecera?cocuId=${id}`).then(data => data);
                    if(!data.mensaje){
                        try {
                            fetch('data:image/jpeg;base64,'+data)
                            .then(res => res.blob())
                            .then(blob => {
                               this.$refs.pond.addFile(new File([blob], "cabecera.png",{ type: "image/png" }));
                            })
                            
                        } catch (error) {console.log(error)}
                    }
                }
                
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }
        },
        
        limpiar() {
            this.form = {
                cocuId: null,

                cocuCups: null,

                cocuNombre: null,

                cocuAgrupacionservicio: null,

                tblConfContratosxservicios: []
            };
        },
        close() {
            this.isNew = true;
            this.dialog = false;
            this.limpiar();
        }
    }
};
</script>

<style>

</style>

<template>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="cotaId" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Tarifarios</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                        agregar
                    </v-btn>
                    <v-form @submit.prevent="subida" enctype="multipart/form-data">
                        <v-dialog v-model="dialogmasivo" persistent width="500">
                            <template v-slot:activator="{ on }">
                                <v-btn color="red lighten-2" dark v-on="on">
                                    Carga masiva
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-title class="headline grey lighten-2" primary-title>
                                    Cargar excel
                                </v-card-title>

                                <v-card-text>
                                    <v-row v-if="!loader">
                                        <v-col cols="12" sm="12" md="12">
                                            <v-file-input outlined v-model="file" show-size label="Excel" @change="onFileChange"></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" sm="12" md="12" class="text-center">
                                            <span class="headline">CARGANDO CUPS....</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" class="text-center">
                                            <v-progress-circular :width="7" :size="150" color="primary" indeterminate></v-progress-circular>
                                        </v-col>
                                    </v-row>

                                </v-card-text>

                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-btn color="primary" text target="_blank" :href="plantilla">
                                        Descargar formato
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="red" v-if="!loader" text @click="dialogmasivo = !dialogmasivo">
                                        cancelar
                                    </v-btn>
                                    <v-btn color="primary" v-if="!loader" text @click="subida">
                                        cargar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-form>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.cotaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>

                                    <v-col cols="9" sm="9" md="9" lg="9">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                CODIGO <span class="primary--text">
                                                    {{ item.cotaCodigo}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                CUPS <span class="green--text"> {{item.cotaCups}}</span>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                CODIGO DESCRIPCION <span class="primary--text">
                                                    {{item.cotaCodigoTexto}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                CUPS DESCRIPCION <span class="primary--text">
                                                    {{item.cotaCupsTexto}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                TIPO <span class="primary--text">
                                                    {{item.tipo.comaNombrelargo}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                VALOR <span class="primary--text">
                                                    {{item.cotaValor}}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="3" sm="3" md="3" lg="3">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="info" class="mr-2" v-on="on" small @click.stop="dialog = true" @click="editar(item.cotaId)">
                                                    <v-icon>create</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">

                                                <v-btn outlined small color="error" v-on="on" @click="eliminar(item.cotaId)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:footer>
                <v-row class="mt-2 pb-5" align="center" justify="center">
                    <span class="mr-4 grey--text">
                        Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                    </span>
                    <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                </v-row>
            </template>
        </v-data-iterator>
        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined label="Codigo" :error="$v.form.cotaCodigo.$error" v-model="form.cotaCodigo"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined label="Codigo descripcion" v-model="form.cotaCodigoTexto"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined label="Cup" :error="$v.form.cotaCups.$error" v-model="form.cotaCups"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined label="Cup descripcion" v-model="form.cotaCupsTexto"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined label="Valor" :error="$v.form.cotaValor.$error" v-model="form.cotaValor"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete v-model="form.cotaTipoTarifario" :error="$v.form.cotaTipoTarifario.$error" :items="tipotarifario" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo" clearable outlined></v-autocomplete>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

    </v-container>
</template>

<script>
import plantilla from '@/assets/files/plantilla_tarifario.xlsx';
import {
  required
}
from 'vuelidate/lib/validators'
export default {
  components: {

  },
  data() {
    return {
      dialog: false,
      isNew: true,
      loader: false,
      Count: 0,
      plantilla: null,
      file: null,
      dialogmasivo: false,
      page: 1,
      pageCount: 0,
      url: "sistema/tarifarios/",
      token: this.$cookies.get("token"),

      form: {
        cotaId: null,
        cotaCodigo: null,
        cotaCodigoTexto: null,
        cotaCups: null,
        cotaCupsTexto: null,
        cotaTipoTarifario: null,
        cotaValor: null,
      },

      filtros: [{
          text: "Codigo",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
        },
        {
          text: "Tipo",
          value: null,
          tipo: "lista",
          lista: [],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
        },
        {
          text: "Valor",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
        }, {
          text: "Cup",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
        },
      ],
      lista: [],
      listaCopia: [],
      tipotarifario: []
    };
  },
  validations: {
    form: {

      cotaCodigo: {
        required,
      },
      cotaCups: {
        required,
      },
      cotaTipoTarifario: {
        required,
      },
      cotaValor: {
        required,
      },

    },
  },
  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.plantilla = plantilla;
    this.$store.commit('setCargaDatos', true);
    this.filtros[1].lista = this.tipotarifario = await this.$apiService.index("sistema/maestra/index/TBL_TIPOTARIFARIOS").then(data => data);
    await this.buscar(this.filtros);
    this.$store.commit('setCargaDatos', false);
    this.limpiar();
  },

  methods: {
    onFileChange(file) {
      this.file = file;
    },
    async searchTari(searchP) {
      this.$store.commit('setCargaDatos', true);
      const data = await this.$apiService.index(`sistema/tarifarios/buscar?${searchP}`).then(data => data);
      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }
      this.$store.commit('setCargaDatos', false);

    },
    async buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      var search = `offset=${(this.page-1)*10}&`;

      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        search = search + `cotaCodigo=${this.filtros[0].value}&`;
      }
      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        search = search + `cotaTipoTarifario=${this.filtros[1].value}&`;
      }
      if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
        search = search + `cotaValor=${this.filtros[2].value}&`;
      }
      if (this.filtros[3].value != null && this.filtros[3].value.toString().replace(/ /g, "") != "") {
        search = search + `cotaCups=${this.filtros[3].value}&`;
      }
      if (search != "") {
        await this.searchTari(search);
      }

    },
    async subida() {
      try {
        if (this.file != null) {
          let formData = new FormData();
          formData.append('file', this.file);
          this.loader = true;
          let data = await this.$apiService.createFile(this.url + "masiva", formData).then(data => data);
          if (data) {
            this.loader = false;
            this.file = null;
            this.$store.commit('setCargaDatos', true);
            await this.buscar(this.filtros);
            this.$store.commit('setCargaDatos', false);
            this.$swal.fire({
              title: "Completado!",
              text: "Subida exitosa",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }

        } else {
          this.loader = false;
          this.$swal.fire({
            title: "Cuidado!",
            text: "Selecciona un archivo primero",
            type: "warnning",
            confirmButtonText: "Ok"
          });
        }

      } catch (error) {
        this.loader = false;
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
      this.dialogmasivo = false;

    },
    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }

          } else {
            var id = this.form.cotaId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              this.lista = this.lista.filter(e => e.cotaId != id);
              this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }

          }
          this.close();
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }

      }
    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            this.listaCopia = this.lista = this.lista.filter(
              e => e.cotaId != id
            );
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);

        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    limpiar() {
      this.form = {
        cotaId: null,
        cotaCodigo: null,
        cotaCodigoTexto: null,
        cotaCups: null,
        cotaCupsTexto: null,
        cotaTipoTarifario: null,
        cotaValor: null,
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>

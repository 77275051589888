<template>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :items-per-page="10" sort-desc sort-by="cotaId" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Servicios</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                        agregar
                    </v-btn>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
            </template>
            <template v-slot:default="props">
                <v-row align="center" justify="center">
                    <v-col v-for="item in props.items" :key="item.coseId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>

                                    <v-col cols="9" sm="9" md="9" lg="9">
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                CODIGO <span class="primary--text">
                                                    {{ item.coseCodigo}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="7" md="7" lg="7">
                                                SEXO <span class="green--text"> {{(item.coseSexo== 0)?'AMBOS':(item.coseSexo== 1)?'HOMBRE':(item.coseSexo== 2)?'MUJER':''}}</span>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                NOMBRE <span class="primary--text">
                                                    {{item.coseNombre}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                TIPO <span class="primary--text">
                                                    {{item.tipo? item.tipo.comaNombrelargo:''}}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="3" sm="3" md="3" lg="3">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="info" class="mr-2" small v-on="on" @click.stop="dialog = true" @click="editar(item.coseId)">
                                                    <v-icon>create</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="error" v-on="on" @click="eliminar(item.coseId)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col cols="12" sm="3" md="3" lg="3">
                                        EDAD INICIAL <span class="primary--text">
                                            {{ item.coseEdadinicio }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3">
                                        EDAD FINAL
                                        <span class="primary--text"> {{ item.coseEdadfinal}} </span>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="3" lg="3">
                                        VALOR
                                        <span class="primary--text"> {{ item.coseValor}} </span>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="6">
                                        RUTA
                                        <span class="primary--text"> {{ item.coseRuta}} </span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>
        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined label="Codigo" v-model="form.coseCodigo"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-text-field outlined v-on:keyup="form.coseNombre = $event.target.value.toUpperCase()" label="Nombre" v-model="form.coseNombre"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="2">
                                    <v-text-field outlined label="Sexo" v-model="form.coseSexo"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="5">
                                    <v-text-field outlined label="Edad inicio" v-model="form.coseEdadinicio"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="5">
                                    <v-text-field outlined label="Edad final" v-model="form.coseEdadfinal"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="5">
                                    <v-text-field outlined label="Valor" v-model="form.coseValor"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="5">
                                    <v-text-field outlined label="Version" v-model="form.hiseVersion"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete outlined v-model="form.coseTipoServicio" :items="tiposervicio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Servicio" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                  <v-autocomplete outlined v-model="form.coseRuta"
                                    :items="tiporutas" item-text="comaNombrelargo" item-value="comaNombrecorto" item-color="primary" label="Ruta"
                                    clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">
                                    <v-autocomplete outlined v-model="form.cosePrimeravez" :items="listaCopia" item-text="coseNombre" item-value="coseId" item-color="primary" label="Servicio de primera vez" clearable>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

    </v-container>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {
      dialog: false,
      isNew: true,
      url: "sistema/servicios/",
      token: this.$cookies.get("token"),

      nameRules: [v => !!v || "Campo requirido"],

      form: {
        coseId: null,

        coseCodigo: null,

        coseSexo: null,

        coseEdadinicio: null,

        coseEdadfinal: null,

        coseTipoServicio: 0,
        coseNombre: null,
        cosePrimeravez: null,
        coseValor: 0,
        hiseVersion: 1
      },
      filtros: [{
          text: "Codigo",
          value: null,
          tipo: "text"
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
        },
        {
          text: "Sexo",
          value: null,
          tipo: "text"
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
        },
        {
          text: "Edad inicio",
          value: null,
          tipo: "text"
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
        },
        {
          text: "Edad final",
          value: null,
          tipo: "text"
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
        }
      ],

      tiposervicio: [],
      lista: [],
      listaCopia: [],
      tiporutas:[]
    };
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    this.listaCopia = this.lista = await this.$apiService.index(this.url + "index").then(data => data);
    this.tiposervicio = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSERVICIOS").then(data => data);
    this.tiporutas =await this.$apiService.index("sistema/maestra/index/TBL_TIPORUTAS").then(data => data);
    this.$store.commit('setCargaDatos', false);
    this.limpiar();
  },

  methods: {

    buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;

      if (
        this.filtros[0].value != null &&
        this.filtros[0].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.lista.filter(
          e => e.coseCodigo.indexOf(this.filtros[0].value) != -1
        );
      }
      if (
        this.filtros[1].value != null &&
        this.filtros[1].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.lista.filter(
          e => e.coseSexo.indexOf(this.filtros[1].value) != -1
        );
      }
      if (
        this.filtros[2].value != null &&
        this.filtros[2].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.lista.filter(
          e => e.coseEdadinicio.indexOf(this.filtros[2].value) != -1
        );
      }
      if (
        this.filtros[3].value != null &&
        this.filtros[3].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.lista.filter(
          e => e.coseEdadfinal.indexOf(this.filtros[3].value) != -1
        );
      }
    },

    async agregar() {
      try {
        if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              this.lista.push(data);
              this.listaCopia = this.lista;
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
        } else {
            var id = this.form.coseId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              this.lista = this.lista.filter(e => e.coseId != id);
              this.lista.push(data);
              this.listaCopia = this.lista;
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
        }
        this.close();
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async eliminar(id) {
      try {
          var result = await this.$swal.fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          }).then(result => result.value);
          if (result) {
            let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
            if (data) {
              this.listaCopia = this.lista = this.lista.filter(
                e => e.coseId != id
              );
              this.$swal.fire(
                "Eliminado!",
                "ha sido eliminado con exito.",
                "success"
              );
            }
          }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
          this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
          this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },

    limpiar() {
      this.form = {
        coseId: null,

        coseCodigo: null,

        coseSexo: null,

        coseEdadinicio: null,

        coseEdadfinal: null,

        coseTipoServicio: 0,
        cosePrimeravez: null,
        coseNombre: null,
        coseValor: 0,
        hiseVersion: 1
      };
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>

<style>
</style>

<style>

</style>

<template>
    <v-container lighten-5>
        <v-data-iterator :items="lista" :items-per-page="10" item-key="cocoId" single-expand sort-desc sort-by="cocoId" class="elevation-1">
            <template v-slot:header>
                <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                <v-toolbar dark color="primary darken-3" class="mb-1">
                    <v-toolbar-title class="white--text">Contratos</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                        agregar
                    </v-btn>
                    <div class="flex-grow-1"></div>
                </v-toolbar>
            </template>
            <template v-slot:default="{ items, isExpanded, expand }">
                <v-row align="center" justify="center">
                    <v-col v-for="item in items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                        <v-card>
                            <v-card-title class="subtitle-1 font-weight-bold">
                                <v-row no-gutters>

                                    <v-col cols="9" sm="9" md="9" lg="9">
                                        <v-row no-gutters>

                                            <v-col cols="12" sm="6" md="6" lg="6">
                                                FECHA INICIO <span class="green--text">
                                                    {{ item.cocoFechainicio}}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="6">
                                                FECHA FINAL <span class="green--text"> {{item.cocoFechafinal}}</span>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                NOMBRE <span class="primary--text">
                                                    {{item.cocoNombre}}

                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="12" lg="12">
                                                TIPO DE CONTRATO <span class="primary--text">{{ item.tipocontrato.comaNombrelargo }}
                                                </span>
                                            </v-col>
                                            <v-col cols="12" sm="5" md="5" lg="5">
                                                ESTADO <span class="primary--text">{{item.estado.comaNombrecorto}}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="3" sm="3" md="3" lg="3">

                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="purple" class="mr-2" small v-on="on" @click.stop="dialog = true" @click="copiar(item.cocoId)">
                                                    <v-icon>mdi-content-copy</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Copiar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined color="info" class="mr-2" small v-on="on" @click.stop="dialog = true" @click="editar(item.cocoId)">
                                                    <v-icon>create</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="success" class="mr-2" v-on="on" @click.stop="dialog1 = true" @click="cocoId = item.cocoId">
                                                    <v-icon>flag</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Metas</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn outlined small color="error" v-on="on" @click="eliminar(item.cocoId)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-row no-gutters>

                                    <v-col cols="12" sm="12" md="3" lg="3">
                                        EMPRESA <span class="primary--text">
                                            {{ item.tblConfPersonasempresas[0].coemNombre }}
                                        </span>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12" lg="12">
                                        <v-row>
                                            <v-col cols="1" sm="1" md="1" lg="1">
                                                <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                    <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                    <v-icon v-else>mdi-chevron-up</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="11" sm="11" md="11" lg="11">
                                                <span class="headline primary--text">SERVICIOS - METAS</span>
                                            </v-col>
                                        </v-row>

                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text v-if="isExpanded(item)">
                                <template v-if="item.tblConfContratosxservicios.length">
                                    <br>
                                    <span class="primary--text">SERVICIOS:</span> <br>
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Cups
                                                    </th>
                                                    <th class="text-left">
                                                        Servicio
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr :key="ser.cocsId+'_SERVICIO'" v-for="ser in item.tblConfContratosxservicios">
                                                    <td v-if="ser.tblConfCup">{{ser.tblConfCup.cocuNombre}}</td>
                                                    <td v-if="ser.tblConfCup">{{ser.tblConfServicios[0].coseNombre}}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                    <!-- <v-chip class="ma-2" small outlined label v-for="ser in item.tblConfContratosxservicios" :key="ser.child">{{ ser.tblConfCup.cocuNombre+' - '+ser.tblConfServicios[0].coseNombre}}</v-chip> -->

                                </template>

                                <template v-if="item.tblConfCupsMetas.length  ">
                                    <br>
                                    <span class="primary--text">METAS:</span> <br>
                                    <v-simple-table dense>
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Cups
                                                    </th>
                                                    <th class="text-left">
                                                        Meta
                                                    </th>
                                                    <th class="text-left">
                                                        Tipo
                                                    </th>
                                                    <th class="text-left">
                                                        # Dias
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr :key="ser.cocmId+'_META'" v-for="ser in item.tblConfCupsMetas">
                                                    <td>{{ ser.tblConfCup ?ser.tblConfCup.cocuNombre:'No registrado'}}</td>
                                                    <td>{{ser.cocmMeta }}</td>
                                                    <td>{{ser.tipometa?ser.tipometa.comaNombrelargo:'No registrado' }}</td>
                                                    <td>{{ser.cocmDias}}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>

                                    <!-- <template v-for="ser in item.tblConfCupsMetas">
                                    <v-chip class="ma-2" small outlined label  :key="ser.cocmId+'_META'" v-if="ser.tblConfCup">{{ser.tblConfCup.cocuNombre+' - '+ ser.cocmMeta}}</v-chip>

                                 </template> -->

                                </template>

                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:no-data>
                <v-row class="mt-2" align="center" justify="center">
                    <v-col cols="10" sm="10" md="10" lg="10">
                        <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                            No se encontro nada.
                        </v-alert>
                    </v-col>
                </v-row>
            </template>
        </v-data-iterator>

        <v-form @submit.prevent="agregar">
            <v-dialog v-model="dialog" persistent width="1000px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>

                                <v-col cols="12" sm="12" md="3">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field :rules="nameRules" outlined v-model="form.cocoFechainicio" required label="Fecha inicio" prepend-icon="event" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.cocoFechainicio" :error="$v.form.cocoFechainicio.$error" @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field :rules="nameRules" outlined v-model="form.cocoFechafinal" required label="Fecha final" prepend-icon="event" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.cocoFechafinal" :error="$v.form.cocoFechafinal.$error" @input="menu2 = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="12" md="6">
                                    <v-text-field outlined :rules="nameRules" required label="Nombre" :error="$v.form.cocoNombre.$error" v-model="form.cocoNombre" v-on:keyup="$data.form.cocoNombre = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined :rules="nameRules" required :error="$v.form.cocoTipoContrato.$error" v-model="form.cocoTipoContrato" :items="tipocontrato" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo De Contrato" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined :rules="nameRules" required :error="$v.form.cocoTipoEstado.$error" v-model="form.cocoTipoEstado" :items="tipoestado" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined :rules="nameRules" required :error="$v.form.coemId.$error" v-model="form.coemId" :items="empresas" item-text="tblConfPersonasempresas[0].coemNombre" item-value="tblConfPersonasempresas[0].coemId" item-color="primary" label="Empresa" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field outlined :rules="nameRules" required label="Porcentaje" v-model="form.cocoPorcentaje" v-on:keyup="form.cocoPorcentaje = $event.target.value.toUpperCase()"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined :rules="nameRules" required v-model="form.cocoTipoPorcentaje" :items="tipoporcentaje" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo De Porcentaje" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined :rules="nameRules" required v-model="form.cocoTipoManualtarifario" :items="tipotarifario" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tarifario" clearable>
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-autocomplete outlined :rules="nameRules" required v-model="form.cocoTipoSalario" :items="tiposalario" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Salario" clearable>
                                        <template v-slot:selection="data">
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.comaNombrecorto"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.dependencia.comaNombrecorto"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>

                                        <template v-slot:item="data">
                                            <template v-if="(typeof data.item !== 'object')">
                                                <v-list-item-content v-text="data.item"></v-list-item-content>
                                            </template>
                                            <template v-else>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.comaNombrelargo"></v-list-item-title>
                                                    <v-list-item-subtitle v-html="data.item.dependencia.comaNombrecorto"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="auto" md="12">
                                    <v-row justify="space-around">
                                        <v-col cols="auto" md="12">
                                            <v-toolbar flat>
                                                <v-toolbar-title>Servicios especificos</v-toolbar-title>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <v-btn text @click="agregartblConfContratosxservicios">Agregar nuevo</v-btn>
                                                <v-divider class="mx-4" inset vertical></v-divider>

                                                <div class="flex-grow-1"></div>
                                            </v-toolbar>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">

                                    <template v-for="(items,index) in form.tblConfContratosxservicios">
                                    <template>
                                        <v-row :key="index" >
                                            <v-col cols="12" sm="12" md="3">
                                                <v-text-field outlined :rules="nameRules" required label="Porcentaje" v-model="items.cocsPorcentaje" v-on:keyup="items.cocsPorcentaje = $event.target.value.toUpperCase()"></v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="4">
                                                <v-autocomplete outlined :rules="nameRules" required v-model="items.cocsTipoPorcentaje" :items="tipoporcentaje" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo De Porcentaje" clearable>
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="3">
                                                <v-autocomplete outlined :rules="nameRules" required v-model="items.cocsTipoManualtarifario" :items="tipotarifario" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tarifario" clearable>
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="3">
                                                <v-autocomplete outlined :rules="nameRules" required v-model="items.cocsTipoEstado" :items="tipoestado2" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable>
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="4">
                                                <v-autocomplete outlined :rules="nameRules" required v-model="items.coseId" :items="servicios" item-text="coseNombre" item-value="coseId" item-color="primary" label="Servicio" clearable>
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="4" v-if="items.cups">
                                                <v-autocomplete outlined :rules="nameRules" v-if="items.cups.cups" required v-model="items.cupsId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter  :items="items.cups.cups" @keypress.enter="searchCup(items)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="3">
                                                <v-text-field outlined :rules="nameRules" required label="Meta" v-model="items.cocsMeta"></v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="4">
                                                <v-autocomplete outlined :rules="nameRules" required v-model="items.cocsTipoMeta" :items="tipometa" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo De Meta" clearable>
                                                </v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="4">
                                                <v-autocomplete outlined v-model="items.coseTipoServicio" :items="tiposervicio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Servicio" clearable></v-autocomplete>
                                            </v-col>
                                            <v-col cols="auto" md="1" v-if="user.username == 'soporte'">
                                                <v-btn text icon color="error" @click="eliminartblConfContratosxservicios(items)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider :key="items.cocsId"></v-divider>
                                    </template>
                                  </template>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
        <v-form @submit.prevent="Metas">
            <v-dialog v-model="dialog1" persistent width="1000px">
                <v-card>
                    <v-card-title>
                        <span class="headline">Metas</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="auto" md="12">
                                    <v-row justify="space-around">
                                        <v-col cols="auto" md="12">
                                            <v-toolbar flat>
                                                <v-toolbar-title>Cups</v-toolbar-title>
                                                <v-divider class="mx-4" inset vertical></v-divider>
                                                <v-btn text @click="agregartblConfCupsMetas">Agregar nuevo</v-btn>
                                                <v-divider class="mx-4" inset vertical></v-divider>

                                                <div class="flex-grow-1"></div>
                                            </v-toolbar>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="12">

                                    <template v-for="(items,index) in tblConfCupsMetas">
                                     <template>  
                                        <v-row :key="index+'_META'">

                                            <v-col cols="12" sm="12" md="4">
                                                <v-autocomplete outlined :rules="nameRules" required v-model="items.cocuId" :loading="items.cups.isLoading" :search-input.sync="items.cups.search" hide-no-data hide-selected no-filter :items="items.cups.cups" @keypress.enter="searchCup(items)" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable>
                                                </v-autocomplete>

                                            </v-col>
                                            <v-col cols="12" sm="12" md="3">
                                                <v-text-field outlined :rules="nameRules" required label="Meta" v-model="items.cocmMeta"></v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="4">
                                                <v-autocomplete outlined :rules="nameRules" required v-model="items.cocmTipoMeta" :items="tipometa" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo De Meta" clearable>
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="3">
                                                <v-text-field outlined :rules="nameRules" required label="Periodo/Dias" v-model="items.cocmDias"></v-text-field>
                                            </v-col>

                                            <v-col cols="auto" md="1">
                                                <v-btn text icon color="error" @click="eliminartblConfCupsMetas(items)">
                                                    <v-icon>delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        </template>
                                    </template>

                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary darken-1" text @click="closeMetas">Cancelar</v-btn>
                        <v-btn color="primary darken-1" dark @click="agregarMetas">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>

    </v-container>
</template>

<script>
import generales from '../../mixins/generales.js';
import {
  required
}
from 'vuelidate/lib/validators';

export default {
  mixins: [generales],
  components: {

  },
  data() {
    return {
      singleExpand: false,
      dialog: false,
      dialog1: false,
      isNew: true,
      isNewMetas: true,
      url: "sistema/Contratos/",
      cocoId: null,
      nameRules: [v => !!v || "Campo requirido"],
      menu: false,
      menu2: false,
      empresas: [],
      servicios: [],
      isLoading: false,
      search: null,
      form: {
        cocoId: null,
        cocoFechainicio: new Date().yyyymmdd(),
        cocoFechafinal: new Date().yyyymmdd(),
        cocoNombre: null,
        cocoTipoContrato: null,
        cocoTipoEstado: null,
        cocoTipoSalario: null,
        coemId: null,
        cocoPorcentaje: null,
        cocoTipoPorcentaje: null,
        cocoTipoManualtarifario: null,
        tblConfContratosxservicios: [
        ]
      },
      tblConfCupsMetas: [],
      filtros: [{
        text: "cocoId",
        value: null,
        tipo: "text"
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
                  listatext: "nombre del campo que se mostrara",
                  listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
                  defaulttext: "tblConfPersonasnaturales",
                  defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                  defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Fecha inicio",
        value: null,
        tipo: "fecha",
        menu: false
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
                  listatext: "nombre del campo que se mostrara",
                  listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
                  defaulttext: "tblConfPersonasnaturales",
                  defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                  defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Fecha final",
        value: null,
        tipo: "fecha",
        menu: false
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
                  listatext: "nombre del campo que se mostrara",
                  listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
                  defaulttext: "tblConfPersonasnaturales",
                  defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                  defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Nombre",
        value: null,
        tipo: "text"
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
                  listatext: "nombre del campo que se mostrara",
                  listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
                  defaulttext: "tblConfPersonasnaturales",
                  defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                  defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Tipo De Contrato",
        value: null,
        tipo: "lista",
        lista: [],
        listatext: "comaNombrelargo",
        listavalue: "comaId"
        //para select y items personalizados
        /*  default: true,
                  defaulttext: "tblConfPersonasnaturales",
                  defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                  defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Estado",
        value: null,
        tipo: "lista",
        lista: [],
        listatext: "comaNombrelargo",
        listavalue: "comaId"
        //para select y items personalizados
        /*  default: true,
                  defaulttext: "tblConfPersonasnaturales",
                  defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                  defaultsubtext: "copeIdentificacion",*/
      }, {
        text: "Empresa",
        value: null,
        tipo: "lista",
        lista: [],
        listatext: "tblConfPersonasempresas[0].coemNombre",
        listavalue: "tblConfPersonasempresas[0].coemId"
        /*si es tipo lista descomentar esta parte*/
        /* lista: [],
                  listatext: "nombre del campo que se mostrara",
                  listavalue: "nombre del valor que se mandara"*/
        //para select y items personalizados
        /*  default: true,
                  defaulttext: "tblConfPersonasnaturales",
                  defaulttext2: ["copnNombre1", "copnNombre2", "copnApellido1", "copnApellido2"],
                  defaultsubtext: "copeIdentificacion",*/
      }],
      lista: [],
      listaCopia: [],
      tipocontrato: [],
      tipotarifario: [],
      tipoporcentaje: [],
      tipoestado: [],
      tipoestado2: [],
      tipoempresa: [],
      tiposalario: [],
      tipometa: [],
      tiposervicio: [],
    };
  },

  computed: {
    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },

  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialog1(val) {
      val || this.close();
    }
  },
  validations: {
    form: {
      cocoFechainicio: {
        required,
      },

      cocoFechafinal: {
        required,
      },

      cocoNombre: {
        required,
      },

      cocoTipoContrato: {
        required,
      },

      cocoTipoEstado: {
        required,
      },
      coemId: {
        required,
      },
      cocoPorcentaje: {
        required,
      },
      cocoTipoPorcentaje: {
        required,
      },
      cocoTipoManualtarifario: {
        required,
      },
    },
  },
  created: async function() {
    this.$store.commit('setCargaDatos', true);
    this.listaCopia = this.lista = await this.$apiService.index(this.url + "index").then(data => data);
    this.tiposervicio = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSERVICIOS").then(data => data);
    this.tipoempresa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOEMPRESA").then(data => data);
    this.filtros[6].lista = this.empresas = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
    this.servicios = await this.$apiService.index("sistema/servicios/index").then(data => data);
    this.filtros[4].lista = this.tipocontrato = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCONTRATO").then(data => data);
    this.tipotarifario = await this.$apiService.index("sistema/maestra/index/TBL_TIPOTARIFARIOS").then(data => data);
    this.tiposalario = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSALARIOMINIMO").then(data => data);
    this.tipometa = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMETA").then(data => data);
    this.tipoporcentaje = await this.$apiService.index("sistema/maestra/index/TBL_TIPOPORCENTAJE").then(data => data);
    this.filtros[5].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CONTRATOS").then(data => data);
    this.tipoestado2 = await this.$apiService.index("sistema/maestra/index/TBL_TIPOESTADO").then(data => data);
    this.$store.commit('setCargaDatos', false);
    this.limpiar();
  },

  methods: {
    async searchCup(item) {
      
      item.cups.isLoading = true;
      item.cups.cups = [];
      if (item.cups.search.length > 0 && item.cups.search.toString().replace(/ /g, "") != "") {
         item.cups.cups = await  this.$apiService.index("sistema/cups/buscar/" + item.cups.search.toUpperCase()).then(data =>  data);
      }
      item.cups.isLoading = false;
      let tblConfContratosxservicios =  this.form.tblConfContratosxservicios;
      this.form.tblConfContratosxservicios = [];
      this.form.tblConfContratosxservicios =tblConfContratosxservicios;
      
    },
    getMaestra(id, maestra) {
      if (id != null) {

        if (maestra.length > 0) {
          var ma = maestra.filter(e => e.comaId == id);
          if (ma.length > 0) {
            var m = ma[0];
            return m.comaNombrelargo;
          }
        }
      }

    },
    buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      if (
        this.filtros[0].value != null &&
        this.filtros[0].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.listaCopia.filter(
          e => e.cocoId == this.filtros[0].value
        );
      }
      if (
        this.filtros[1].value != null &&
        this.filtros[1].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.listaCopia.filter(
          e => e.cocoFechainicio == this.filtros[1].value
        );
      }
      if (
        this.filtros[2].value != null &&
        this.filtros[2].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.listaCopia.filter(
          e => e.cocoFechafinal == this.filtros[2].value
        );
      }
      if (
        this.filtros[3].value != null &&
        this.filtros[3].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.listaCopia.filter(
          e => e.cocoNombre.indexOf(this.filtros[3].value) != -1
        );
      }
      if (
        this.filtros[4].value != null &&
        this.filtros[4].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.listaCopia.filter(
          e => e.cocoTipoContrato == this.filtros[4].value
        );
      }
      if (
        this.filtros[5].value != null &&
        this.filtros[5].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.listaCopia.filter(
          e => e.cocoTipoEstado == this.filtros[5].value
        );
      }
      if (
        this.filtros[6].value != null &&
        this.filtros[6].value.toString().replace(/ /g, "") != ""
      ) {
        this.lista = this.listaCopia.filter(
          e => e.coemId == this.filtros[6].value
        );
      }
    },

    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error) {
        try {
          if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              this.listaCopia = this.lista = await this.$apiService.index(this.url + "index").then(data => data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }

          } else {
            var id = this.form.cocoId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
            if (data) {
              this.listaCopia = this.lista = await this.$apiService.index(this.url + "index").then(data => data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          }
          this.close();
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
      }
    },
    async agregarMetas() {

      try {
        if (this.isNewMetas) {
          let data = await this.$apiService.create(this.url + "createMetas", {
            tblConfCupsMetas: this.tblConfCupsMetas
          }).then(data => data);
          if (data) {
            this.$swal.fire({
              title: "Completado!",
              text: "Registro exitoso",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }

        } else {
          var id = this.form.cocoId;
          let data = await this.$apiService.update(this.url + "updateMetas/" + id + "/", {
            tblConfCupsMetas: this.tblConfCupsMetas
          }).then(data => data);
          if (data) {
            this.$swal.fire({
              title: "Completado!",
              text: "Registro exitoso",
              icon: "success",
              confirmButtonText: "Ok"
            });
          }
        }
        this.closeMetas();
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            this.listaCopia = this.lista = this.lista.filter(
              e => e.cocoId != id
            );
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }

        }

      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
          let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
          if (data) {
            this.form = data;
            if (this.form.tblConfContratosxservicios.length > 0) {
              for (let r of this.form.tblConfContratosxservicios) {
                r.index = this.form.tblConfContratosxservicios.indexOf(r);
                r.cups = {
                  search: null,
                  isLoading: false,
                  cups: [r.tblConfCup]
                };
              }
            }
          this.isNew = false;
        }

      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async copiar(id) {
      try {
        let data = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        if (data) {
          this.form = data;
          this.form.cocoId = null;

          this.form.cocoFechainicio = new Date().yyyymmdd();

          this.form.cocoFechafinal = new Date().yyyymmdd();

          this.form.cocoNombre = null;
          if (this.form.tblConfContratosxservicios.length > 0) {
            for (var i = 0; i < this.form.tblConfContratosxservicios.length; i++) {
              var r = this.form.tblConfContratosxservicios[i];
              r.cocsId = null;
              r.cups = {
                search: null,
                isLoading: false,
                cups: []
              };
              r.cups.cups.push(r.tblConfCup);

            }

          }
          // = data
        }

        this.isNew = true;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    agregartblConfContratosxservicios() {
      this.form.tblConfContratosxservicios.push({
        cocsId: null,
        // cocsPorcentaje: null,
        //cocsTipoPorcentaje: null,
        cocsTipoEstado: null,
        cocsTipoServicio: null,
        cocsTipoMeta: null,
        cocsMeta: null,
        coseTipoServicio:null,
        // cocsTipoManualtarifario: null,
        coseId: null,
        cupsId: null,
        cups: {
          search: null,
          isLoading: false,
          cups: []
        },
      });
    },
    eliminartblConfContratosxservicios(item) {
      this.form.tblConfContratosxservicios = this.form.tblConfContratosxservicios.filter(
        e =>
        this.form.tblConfContratosxservicios.indexOf(e) !=
        this.form.tblConfContratosxservicios.indexOf(item)
      );
    },
    agregartblConfCupsMetas() {
      this.tblConfCupsMetas.push({
        cocoId: this.cocoId,
        cocmTipoMeta: null,
        cocmMeta: null,
        cocmDias: null,
        cosmId: null,
        cocuId: null,
        cups: {
          search: null,
          isLoading: false,
          cups: []
        },
      });
    },
    eliminartblConfCupsMetas(item) {
      this.tblConfCupsMetas = this.tblConfCupsMetas.filter(
        e =>
        this.tblConfCupsMetas.indexOf(e) !=
        this.tblConfCupsMetas.indexOf(item)
      );
    },
    limpiar() {
      this.form = {
        cocoId: null,

        cocoFechainicio: new Date().yyyymmdd(),

        cocoFechafinal: new Date().yyyymmdd(),

        cocoNombre: null,

        cocoTipoContrato: null,

        cocoTipoEstado: null,
        cocoTipoSalario: null,
        coemId: null,
        cocoPorcentaje: null,
        cocoTipoPorcentaje: null,
        cocoTipoManualtarifario: null,

        tblConfContratosxservicios: []
      };
      this.tblConfContratosxservicios = [];
    },
    close() {
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    },
    closeMetas() {
      this.isNewMetas = true;
      this.dialog1 = false;
      this.tblConfCupsMetas = [];
      this.cocoId = null;
    }
  }
};

</script>
